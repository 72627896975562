import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"

const FAQ = () => {
  return (
    <Layout>
      <Seo title="FAQ | Resistant.AI" />
      faq
    </Layout>
  )
}

export default FAQ
